import React, { useEffect, useState } from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import Header from '../Header'
import Loader from '../Loader/Loader';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
const LanguageMovies = () => {
    const paramdata = useParams();
    // console.log(paramdata);
    const [loading, setLoading] = useState(true);
    const [sliders, setSlider] = useState([]);
    const [movies, setMovies] = useState([]);
    useEffect(() => {
        loadMovies()
    }, [])
    const loadMovies = async () => {
        setLoading(true)
        let formData = new FormData();
        formData.append('data[salt]', process.env.REACT_APP_API_SALT);
        formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
        formData.append('data[lang_id]', paramdata.id);
        formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
        // formData.append('data[movie_id]', id);
        // console.log(paramdata.id);
        axios.post(`${process.env.REACT_APP_API_URL}/movies_by_language`, formData).then((response) => {
            setLoading(false);
            console.log(response.data.VIDEO_STREAMING_APP);
            setMovies(response.data.VIDEO_STREAMING_APP)
            // setSlider(response.data.VIDEO_STREAMING_APP.slider)
            // setMovies(response.data.VIDEO_STREAMING_APP.movies)
        })
    }
    const CustomNextArrow = ({ onClick }) => (
        <button className="custom-next-arrow" onClick={onClick}>
            <FontAwesomeIcon aria-hidden="true" icon={faAngleRight} />
        </button>
    );
    const CustomPrevArrow = ({ onClick }) => (
        <button className="custom-prev-arrow" onClick={onClick}>
            <FontAwesomeIcon aria-hidden="true" icon={faAngleLeft} />
        </button>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            {
                loading ? (<Loader loading={loading} />) : (
                    <>
                        <Header />
                        <div className="slider-container mt-5watc" >
                            <h3 className='home-heading'>Movies</h3>
                            <Row>
                                {
                                    movies.map((card, index) => {
                                        console.log(index);
                                        return (
                                            <Col md={2} sm={4} xs={4}>
                                                <Link to={`/watch/${card.movie_id != null ? card.movie_id : card.movie_id}`}>
                                                    <Card.Body>
                                                        <Image src={card.movie_poster}
                                                        style={{width:"100%"}}
                                                        ></Image>
                                                    </Card.Body>
                                                    {/*  <div key={index} className="card-wrapper">
                                                    <div className="card">
                                                        <div className="image">
                                                            <img 
                                                            src={card.movie_poster != null ? card.movie_poster : card.movie_poster} 
                                                            alt={card.movie_poster} 
                                                            className="card-img-top slick-slider-img" />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </Link>
                                            </Col>
                                        )
                                    })}
                            </Row>
                        </div>
                    </>
                )
            }
        </>
    )
}
export default LanguageMovies