import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Slider = ({ sliders }) => {
    // const [device, setDevice] = useState('Android');
    // console.log(sliders);
    // return
    useEffect(() => {

       AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
          }); 
      
        // console.log(window.navigator.userAgentData.platform);
        // setDevice(window.navigator.userAgentData.platform ? window.navigator.userAgentData.platform : null )
    }, [])
    return (
        <Carousel className=''  
        data-aos="fade-up" data-aos-delay="100"
        >
            {sliders.map((slider, index) => (
               
                    <Carousel.Item
                        interval={50000}
                        key={index}
                    >
                         <Link to={`/watch/${slider.slider_post_id}/${slider.slider_title}`} >
                        {/* <div className="overlay"></div> */}
                        <img
                            key={index}
                            className="d-block w-100 carousel-img"
                            // src={device === "Android" ? slider.vertical_slider_image : slider.vertical_slider_image}
                            src={slider.vertical_slider_image }
                            alt={slider.slider_title}
                        />
                        <Carousel.Caption>
                            {/* <h3 className="animated-text">{slider.slider_title}</h3> */}
                            {/* <p className="animated-text">{slider.description}</p> */}
                            {/* <Link to={`/movies/${slider.slider_post_id}`} className="animated-text  btn btn-sm btn-primary"></Link> */}
                        </Carousel.Caption>
                        </Link>
                    </Carousel.Item>
               
            ))}
        </Carousel>
    );
}
export default Slider