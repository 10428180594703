import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <Container className='mt-1 mb-5 footer'>
      <Row>
        <Col md={12} sm={12} xs={12}>
        <hr style={{
          border:"1px solid white",
        }} />
        </Col>
        <Col md={6} sm={6} xs={6}>
        <Row>
          <Col sm={12} md={12} xs={12}><Link to="https://api.tamashatv.com/page/about-us">About Us</Link></Col>
          <Col sm={12} md={12} xs={12}><Link to="https://api.tamashatv.com/page/terms-and-conditions">Terms & Conditions</Link></Col>
          <Col sm={12} md={12} xs={12}><Link to="https://api.tamashatv.com/page/privacy-policy">Privacy Policy</Link></Col>
          <Col sm={12} md={12} xs={12}><Link to="https://api.tamashatv.com/page/contact-us">Contact Us</Link></Col>
        </Row>
       
        </Col>

        <Col md={6} sm={6} xs={6}>
        <Row>
          <Col sm={12} md={12} xs={12}><Link to="https://api.tamashatv.com/page/refund-policy">Refund Policy</Link></Col>
          {/* <Col sm={12} md={12} xs={12}><Link to="https://api.tamashatv.com/page/faq">FAQ</Link></Col> */}
          <Col sm={12} md={12} xs={12}><Link to="https://api.tamashatv.com/page/grieviance-policy">Grievance Policy</Link></Col>
          
        </Row>
       
        </Col>

      </Row>
    </Container>
    </>
  )
}

export default Footer