import React, { useRef, useState } from 'react'
import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
const Login = () => {
    let navigate = useNavigate();
    const { reset } = useForm();
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']);
    const [error, setError] = useState('');
    /*  
     const otpVerifyBtn = useRef(null);
     const mobileOTPBtn = useRef(null);
     const otpField = useRef(null) */
    // const navigate = useNavigation();
    const mobileNumberInput = useRef(null)

    const setMobileNumber = (e) => {
        const mobile = e.target.value;
        if (/^\d{0,10}$/.test(mobile)) {
            // setValue(inputValue);
            if (mobile.length === 10) {
                mobileNumberInput.current.blur();
            }
            setPhone(e.target.value)
        }
        /* if (event.target.value.length === 10) {
            setPhone(event.target.value)
        } */
    }
    const getOTP = () => {
        if (phone.length === 10) {
            let formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            formData.append('data[username]', phone);
            axios.post(`${process.env.REACT_APP_API_URL}/login`, formData)
                .then((response) => {
                    localStorage.setItem('phone', phone)
                    const res = response.data;
                    const msg = res.VIDEO_STREAMING_APP[0].msg;
                    toast(msg);
                    setTimeout(() => {
                        navigate('/otp')
                    }, 500);
                   
                    // setData(response.data);
                    // setLoading(false);
                })
                .catch((error) => {
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: "Bounce",
                    });
                    // setError(error);
                    // setLoading(false);
                });
            /*  otpField.current.classList.remove('none')
             mobileOTPBtn.current.classList.add('none')
             otpVerifyBtn.current.classList.remove('none')
             mobileNumberInput.current.classList.add('none') */
        } /* else {
            otpField.current.classList.add('none')
            mobileOTPBtn.current.classList.remove('none')
            mobileNumberInput.current.classList.remove('none')
        } */
    }

    const handleSubmit = (e) => {
        try {
            e.preventDefault();
            // console.log('submit button clicked');
            // alert('handle submit');
            if (otp.some((digit) => digit === '')) {
                setError('Please enter a valid 4-digit OTP');
            } else {
                // const formatedOtp = otp.replace(new RegExp(',', 'g'), '');
                const strOtp = otp.toString()
                const formatedOTP = strOtp.replaceAll(',', '');
                let formData = new FormData();
                formData.append('data[salt]', process.env.REACT_APP_API_SALT);
                formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
                formData.append('data[username]', phone);
                formData.append('data[otp]', formatedOTP);
                axios.post(`${process.env.REACT_APP_API_URL}/login_with_otp`, formData)
                    .then((response) => {
                        const resData = response.data.VIDEO_STREAMING_APP
                        resData[0].success === '1' ? toast(resData[0].msg) : toast.error(resData[0].msg)
                        const user_image = resData[0].user_image
                        const exp_date = resData[0].user_profiles[0].exp_date
                        const __exp_date = new Date(exp_date * 1000)
                        const __current_full_date = new Date();
                        const plan_active = __exp_date > __current_full_date ? true : false
                        localStorage.setItem("user_image", resData[0].user_image);
                        localStorage.setItem("name", resData[0].name);
                        localStorage.setItem("user_id", resData[0].user_id);
                        localStorage.setItem("phone", resData[0].phone);
                        localStorage.setItem("is_parent_user", resData[0].is_parent_user);
                        localStorage.setItem("plan_active", plan_active);
                        if (plan_active === true)
                            navigate('/')
                        else
                            navigate('/plans')
                        const plan_amount = resData[0].user_profiles[0].plan_amount
                        const subscription_id = resData[0].user_profiles[0].subscription_id
                        const is_parent_user = resData[0].user_profiles[0].is_parent_user
                        reset();
                        // setData(response.data);
                        // setLoading(false);
                    })
                    .catch((error) => {
                        // console.log(error);
                        toast.error(error)
                        // setError(error);
                        // setLoading(false);
                    });
                setError('');
                // alert(`OTP Submitted: ${otp.join('')}`);  
            }
        } catch (error) {
            toast.error(error)
            // console.log(error);
        }
    }
    return (
        <>
            <Container>
                <Row>
                    <Col sm={12} md={{ span: 4, offset: 4 }} lg={{ span: 4, offset: 4 }}>

                        <Row className='' >
                            <Col md={12} sm={12}>
                                <Link to="/" className='p-2'>
                                    <Image className='login-logo' src={`${process.env.PUBLIC_URL}/logo/masti_plus_logo.svg`} />
                                </Link>
                            </Col>
                            <Col md={12} sm={12} className='mt-10 login-bg' >
                                <Image className='mobile-frame' src={`${process.env.PUBLIC_URL}/images/login_components/mobile.png`} />
                                <Image className='mobile-frame-inside' src={`${process.env.PUBLIC_URL}/images/login_components/mobile_inside_image.png`} />
                                <Image className='mobile-frame-more-like' src={`${process.env.PUBLIC_URL}/images/login_components/More_like_this.png`} />
                                <div class="login-overlay"></div>
                            </Col>
                            <Col md={12} sm={12} className='mobile-number-form'>

                                <Col md={12} sm={12} xs={12} className='mt-5'>
                                    <Form.Control
                                        style={{ fontSize: '16px', padding: '2%' }}
                                        ref={mobileNumberInput}
                                        onChange={setMobileNumber}
                                        className=''
                                        value={phone}
                                        type="number"
                                        placeholder="Your Mobile Number"
                                    />
                                    <Button className='get-otp-btn' onClick={getOTP} variant="primary" type='button'>
                                        Sign up
                                    </Button>
                                </Col>

                                <Col md={12}  sm={12} style={{background:"black",padding:"15px",marginTop:"40%",fontSize:"12px"}}>
                                    <span style={{color:"white"}}>By Continuing, you agree to Tamasha TV 
                                    <Link style={{color:"red"}} to="https://api.tamashatv.com/page/terms-and-conditions"> Terms and conditions </Link>
                                    and <Link style={{color:"red"}} to="https://api.tamashatv.com/page/privacy-policy">Privacy Policy</Link>
                                    </span>
                                
                                </Col>
                            </Col>

                        </Row>
                        <Row className='login-bottom-text' >
                            <span className='login-heading-1'>Sign up to watch</span>
                            <span className='login-heading-2'>Premium</span>
                            <span className='login-heading-3'>Movies and Short Films</span>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    )
}
export default Login