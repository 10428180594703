import React from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";

const Loader = ({loading}) => {
   
    return (
        <div className="loader">
            <PropagateLoader color={"rgb(225 109 1)"}  />
        </div>
    )

}
export default Loader