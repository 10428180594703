import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faBookmark, faCirclePause, faCirclePlay, faForward, faPause, faPlay, faShare } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import ReactPlayer from 'react-player';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MoreLike from './MoreLike';
import Header from '../Header';
import axios from 'axios';
import Loader from '../Loader/Loader';
import { toast } from 'react-toastify';
import {
    faBookmark as regularBookmark,
} from '@fortawesome/free-regular-svg-icons';
export const Watch = () => {
    const playerRef = useRef(null);
    const { id } = useParams();
    const [movieimage, setMovieImage] = useState(null)
    const [genres, setGenres] = useState([])
    const [movietitle, setMovieTitle] = useState(null)
    const [moviedescription, setMovieDescription] = useState(null)
    const [relatedmovies, setRelateMovies] = useState([])
    const [videourl, setVideoUrl] = useState([])
    const [releaseyear, setReleaseYear] = useState(null)
    const [loading, setLoading] = useState(true);
    const [played, setPlayed] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [light, setLight] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const [in_watchlist, setInWatchList] = useState(false);
    const [sharebtn, setShareBtn] = useState(null);
    const [controlsVisible, setControlsVisible] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isSeeking, setIsSeeking] = useState(false);
    const [movieDuration, setMovieDuration] = useState(null);
    const [ageCertificate, setAgeCertificate] = useState(null)
    const [movieLanguage, setMovieLanguage] = useState(null)
    const [volume, setVolume] = useState(1); // Volume between 0 and 1
    // const [playbackRate, setPlaybackRate] = useState(1); // Normal speed
    // const [watchedtime, setWatchedTime] = useState(0);
    let watchedtime = 0
    const navigate = useNavigate(); // Use useNavigate inside the component body
    // Function to forward the video by 5 seconds
    const forwardVideo = () => {
        if (playerRef.current) {
            playerRef.current.seekTo(playerRef.current.getCurrentTime() + 5, 'seconds');
        }
    };
    // Function to reverse the video by 5 seconds
    const reverseVideo = () => {
        if (playerRef.current) {
            playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5, 'seconds');
        }
    };
    const handleSetPlaybackRate = (event) => {
        const rate = parseFloat(event.target.value)
        setPlaybackRate(rate);
    };
    // Handle key press events
    const handleKeyPress = (event) => {
        console.log(event.key);
        switch (event.key) {
            case 'ArrowRight':
            case 'L':
            case 'l':
                forwardVideo();
                break;
            case 'ArrowLeft':
            case 'J':
            case 'j':
                reverseVideo();
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        getMovieDetails(id)
        handleUserSubscription();
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [id]);
    const getMovieDetails = async (id) => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
            formData.append('data[movie_id]', id);
            axios.post(`${process.env.REACT_APP_API_URL}/movies_details`, formData).then((response) => {
                setLoading(false);
                console.log(response.data);
                // console.log(response.data.VIDEO_STREAMING_APP);
                const formated_url = response.data.VIDEO_STREAMING_APP.movie_image.replaceAll(' ', '%20')
                const timeWatched = (response.data.VIDEO_STREAMING_APP.timeWatched)
                setInWatchList(response.data.VIDEO_STREAMING_APP.in_watchlist)
                setShareBtn(response.data.VIDEO_STREAMING_APP.share_url)
                const timewatchArr = timeWatched.split(':');
                // setWatchecTime(totalTime)
                // console.log(timewatchArr);̦
                const totalTime = parseFloat((timewatchArr[1] * 60) + parseFloat(timewatchArr[2]) + parseFloat(timewatchArr[0] * 3600)).toFixed(2);
                // console.log(totalTime);
                watchedtime = totalTime;
                setMovieImage(formated_url);
                setGenres(response.data.VIDEO_STREAMING_APP.genre_list);
                setMovieTitle(response.data.VIDEO_STREAMING_APP.movie_title);
                setMovieDescription(response.data.VIDEO_STREAMING_APP.description);
                setRelateMovies(response.data.VIDEO_STREAMING_APP.related_movies);
                setVideoUrl(response.data.VIDEO_STREAMING_APP.video_url);
                setReleaseYear(response.data.VIDEO_STREAMING_APP.release_year);
                setAgeCertificate(response.data.VIDEO_STREAMING_APP.age_certificate);
                setMovieLanguage(response.data.VIDEO_STREAMING_APP.language_name);
                setMovieDuration(response.data.VIDEO_STREAMING_APP.movie_duration);
            })
        } catch (error) {
            setLoading(false);
        }
    }
    const handleMoreLikeClick = (newId) => {
        console.log(newId);
        navigate(`/watch/${newId}`);
    };
    const handleProgress = (state) => {
        try {
            const timeduration = watchedtime > 0 ? watchedtime : parseFloat(state.playedSeconds).toFixed(2)
            console.log(timeduration);
            setCurrentTime(state.playedSeconds);
            const user_id = localStorage.getItem('user_id') != '' ? localStorage.getItem('user_id') : null;
            const formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            formData.append('data[user_id]', user_id);
            formData.append('data[video_id]', id);
            formData.append('data[video_type]', 'movie');
            formData.append('data[duration]', timeduration);
            axios.post(`${process.env.REACT_APP_API_URL}/add_watched_time_log`, formData)
                .then((response) => {
                    console.log(response.data);
                });
            // 
        } catch (error) {
            console.error(error);
        }
    }
    const handleUserSubscription = () => {
        const plan_status = localStorage.getItem('plan_active');
        const user_id = localStorage.getItem('user_id');
        console.log(plan_status);
        if (user_id == undefined) {
            setPlaying(false)
            navigate('/login');
        }
        if (plan_status === 'false' || plan_status === undefined) {
            console.log('not subscribed ..');
            setPlaying(false)
            navigate('/plans');
        }
        // setPlaying(prevPlaying => !prevPlaying);
    }
    const handleReady = useCallback(() => {
        if (!isReady) {
            console.log(`watched time == ${watchedtime}`);
            playerRef.current.seekTo(watchedtime, "seconds");
            setIsReady(true);
            setPlaying(true)
        }
    }, [isReady]);
    const handleWatchListAdd = async () => {
        try {
            // setLoading(true);
            let formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
            formData.append('data[post_id]', id);
            formData.append('data[post_type]', 'Movies');
            axios.post(`${process.env.REACT_APP_API_URL}/watchlist_add`, formData).then((response) => {
                // setLoading(false);
                // console.log(response.data);
                setInWatchList(true)
                toast('Added to watchlist!');
                // watchlist_btn.current.classList.add('none')
            });
        } catch (error) {
            toast.error(error)
        }
    }
    const handleWatchListRemove = async () => {
        try {
            // setLoading(true);
            let formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
            formData.append('data[post_id]', id);
            formData.append('data[post_type]', 'Movies');
            axios.post(`${process.env.REACT_APP_API_URL}/watchlist_remove`, formData).then((response) => {
                // setLoading(false);
                // console.log(response.data);
                setInWatchList(false)
                toast('Removed from watchlist!');
                // watchlist_btn.current.classList.add('none')
            });
        } catch (error) {
            toast.error(error)
        }
    }
    const handleForward = () => {
        playerRef.current.seekTo(currentTime + 10);
    };
    const handleBackward = () => {
        playerRef.current.seekTo(currentTime - 10);
    };
    const handleSeek = (event) => {
        const seekTime = (event.target.value / 100) * duration;
        playerRef.current.seekTo(seekTime);
    };
    const handlePlayPause = () => {
        handleUserSubscription();
        setPlaying(!playing);
    };
    const handleDuration = (duration) => {
        console.log(duration);
        setDuration(duration);
    };
    const toggleControls = () => {
        setControlsVisible((prev) => !prev);
    };
    const showControls = () => {
        setControlsVisible(true);
    };
    const hideControls = () => {
        setControlsVisible(false);
    };
    const handleSeekMouseUp = () => {
        setIsSeeking(false);
    };
    const togglePictureInPicture = async () => {
        if (document.pictureInPictureEnabled) {
            if (document.pictureInPictureElement) {
                await document.exitPictureInPicture();
            } else {
                await playerRef.current.getInternalPlayer().requestPictureInPicture();
            }
        }
    };
    const handleSpeedChange = (event) => {
        const newSpeed = parseFloat(event.target.value);
        setPlaybackRate(newSpeed);
        playerRef.current.getInternalPlayer().setPlaybackRate(newSpeed);
    };
    const handleVolumeChange = (event) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
    };
    const handleSeekMouseDown = () => {
        setIsSeeking(true);
    };
    const skipTime = 20; // seconds to skip
    const handleDoubleTap = (direction) => {
        const player = playerRef.current.getInternalPlayer();
        if (player) {
        //   const currentTime = currentTime;
          if (direction === 'forward') {
            playerRef.current.seekTo(Math.min(currentTime + skipTime, duration), 'seconds');
          } else if (direction === 'backward') {
            playerRef.current.seekTo(Math.max(currentTime - skipTime, 0), 'seconds');
          }
        }
      };
      const handleDoubleClick = (e) => {
        const { clientX } = e;
        const { clientWidth } = e.currentTarget;
        const middle = clientWidth / 2;
        if (clientX < middle) {
          handleDoubleTap('backward');
        } else {
          handleDoubleTap('forward');
        }
      };
    const backgroundImageStyle = movieimage ? `${movieimage.replace(' ', '%')}` : 'none'
    return (
        <>
            {
                loading ? (<Loader loading={loading} />) :
                    (
                        <>
                            <Header />
                            <div className='video-player-wrapper '
                                onMouseEnter={showControls}
                                // onMouseLeave={showControls}
                                // onMouseLeave={hideControls}
                                // onDoubleClick={handleDoubleClick} 
                            >
                                <ReactPlayer
                                    ref={playerRef}
                                    // Disable download button
                                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                    // Disable right click
                                    onContextMenu={e => e.preventDefault()}
                                    className="react-player"
                                    url={videourl}
                                    loop={true}
                                    onClick={toggleControls}
                                    onDuration={handleDuration}
                                    // onSeek={handleSeek}
                                    onProgress={handleProgress}
                                    playbackRate={playbackRate}
                                    controls={false}
                                    width="100%"
                                    height="100%"
                                    volume={volume}
                                    // onHover={toggleControls},
                                    playing={playing}
                                    onReady={handleReady}
                                // onProgress={handleProgress}
                                // onDuration={handleDuration}
                                // playIcon={<button className='watch_now_play_btn' onClick={handleUserSubscription} ></button>}
                                // light={backgroundImageStyle}
                                /* light={
                                    <img src={backgroundImageStyle} alt={movietitle} width="100%" height="60%" />
                                } */
                                />
                                {controlsVisible && (
                                    <input
                                        type="range"
                                        className="seek-bar"
                                        value={(currentTime / duration) * 100 || 0}
                                        onChange={handleSeek}
                                        // onChange={handleSeekChange}
                                        onMouseDown={handleSeekMouseDown}
                                        onMouseUp={handleSeekMouseUp}
                                    />
                                )}
                                {controlsVisible && (
                                    <div className="controls">
                                        <button className="backward-button" onClick={reverseVideo}><FontAwesomeIcon icon={faBackward} /></button>
                                        <button className="play-button" onClick={handlePlayPause}>
                                            {playing ? <FontAwesomeIcon icon={faCirclePause} /> : <FontAwesomeIcon icon={faCirclePlay} />}
                                        </button>

                                        <button className="forward-button" onClick={forwardVideo}><FontAwesomeIcon icon={faForward} /></button>
                                        {/* <button className="pip-button" onClick={togglePictureInPicture}>PiP</button> */}
                                        <input
                                            type="range"
                                            className="volume-bar"
                                            min="0"
                                            max="1"
                                            step="0.1"
                                            value={volume}
                                            onChange={handleVolumeChange}
                                        />
                                      {/*   <select className="speed-control" onChange={handleSpeedChange} value={playbackRate}>
                                            <option value="0.5">0.5x</option>
                                            <option value="1">1x</option>
                                            <option value="1.5">1.5x</option>
                                            <option value="2">2x</option>
                                        </select>  */}
                                        {/* <button className="forward-button" onClick={forwardVideo}><FontAwesomeIcon icon={faForward} /></button> */}
                                    </div>
                                )}
                            </div>
                            <Row className='mt-minus-4 ml-1'>
                                <Col md={10} sm={10} xs={10}>
                                    <h5 className='movie-title'>{movietitle}</h5>
                                    <small className='movie-title'>{
                                        genres.map((gen) => (
                                            gen.genre_name
                                        ))
                                    } | {releaseyear} | {ageCertificate} | {movieLanguage} | {movieDuration}
                                    </small>
                                </Col>
                                <Col md={2} sm={2} xs={2} className='watchlist_share_btn'>
                                    {
                                        in_watchlist == false ? (<FontAwesomeIcon className='watchlist_btn' onClick={handleWatchListAdd} icon={regularBookmark} aria-hidden="true" />) : (<FontAwesomeIcon className='watchlist_btn' onClick={handleWatchListRemove} icon={faBookmark} aria-hidden="true" />)
                                    } &nbsp;
                                    <FontAwesomeIcon onClick={() => {
                                        navigator.clipboard.writeText(sharebtn)
                                        toast('Copied to clipboard!');
                                    }
                                    } icon={faShare} aria-hidden="true" />
                                </Col>
                                <Col sm={12} md={12} className='movie-description'>
                                    <p>
                                        {moviedescription}
                                    </p>
                                </Col>
                            </Row>
                            {/* </div> */}
                            <MoreLike relatedmovies={relatedmovies} onMoreLikeClick={handleMoreLikeClick} />
                        </>
                    )
            }
        </>
    )
}
export default Watch