import React, { useContext, useEffect, useState } from 'react'
import Slider from '../Slider'
import TopTrendingMovies from '../TopTrendingMovies'
import MostWatched from '../MostWatched'
import Movies from '../Movies'
import RecentWatch from '../RecentWatch'
import Header from '../Header'
import axios from 'axios'
import Loader from '../Loader/Loader'
import LatestMovies from '../Movies/LatestMovies'
import LatestShows from '../Movies/LatestShows'
import PopularMovies from '../Movies/PopularMovies'
import Languages from './Languages'
import Genres from './Genres'
import Footer from '../Footer'
import { AppDownload } from '../AppDownload/AppDownload'
import { SearchGlobalContext } from '../Search/SearchGlobalContext'
import Search from '../Search/Search'
import HomeSliderSection from './HomeSliderSection'
import HomeSection from './HomeSection'
const Home = () => {
  const [sliders, setSlider] = useState([])
  const [topTrendings, setTopTrendings] = useState([])
  const [recentlywatched, setRecentlyWatched] = useState([])
  const [homeslidersection, setHomeSliderSection] = useState([])
  const [homesection, setHomeSection] = useState([])
  // const [mostwatched, setMostWatched] = useState([])
  // const [movies, setMovieData] = useState([])
  const [loading, setLoading] = useState(true);
  // const [latestmovies, setLatestMovies] = useState([]);
  // const [latestshows, setLatestShows] = useState([]);
  // const [popularmovies, setPopularMovies] = useState([]);
  const [planStatus, setPlanStatus] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [genres, setGenres] = useState([]);
  const {searchResults} = useContext(SearchGlobalContext)
  useEffect(() => {
    getHomePageData()
    recentlyWatched()
    const pc = localStorage.getItem('plan_active');
    setPlanStatus(pc)
    // console.log(localStorage.getItem('user_id'));
  }, [])
  const getHomePageData = () => {
    setLoading(true)
    let formData = new FormData();
    formData.append('data[salt]', process.env.REACT_APP_API_SALT);
    formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
    formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
    axios.post(`${process.env.REACT_APP_API_URL}/homeV2`, formData).then((response) => {
      setLoading(false)
      // console.log(response.data);
      // console.log(response.data.VIDEO_STREAMING_APP.recently_watched);
      console.log(response.data.VIDEO_STREAMING_APP);
      setSlider(response.data.VIDEO_STREAMING_APP.slider)
      setHomeSliderSection(response.data.VIDEO_STREAMING_APP.home_slider_sections)
      setHomeSection(response.data.VIDEO_STREAMING_APP.home_sections)
      setTopTrendings(response.data.VIDEO_STREAMING_APP.home_slider_sections[0].home_content)
/*       setMovieData(response.data.VIDEO_STREAMING_APP.home_slider_sections[1].home_content)
      setMostWatched(response.data.VIDEO_STREAMING_APP.home_slider_sections[2].home_content)
      setRecentlyWatched(response.data.VIDEO_STREAMING_APP.recently_watched)
      setLatestMovies(response.data.VIDEO_STREAMING_APP.home_sections[0].home_content)
      setLatestShows(response.data.VIDEO_STREAMING_APP.home_sections[1].home_content)
      setPopularMovies(response.data.VIDEO_STREAMING_APP.home_sections[2].home_content)
       */
      setLanguages(response.data.VIDEO_STREAMING_APP.language)
      setGenres(response.data.VIDEO_STREAMING_APP.genres)
    })
  }
  const recentlyWatched = () => {
    setLoading(true)
    let formData = new FormData();
    formData.append('data[salt]', process.env.REACT_APP_API_SALT);
    formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
    formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
    axios.post(`${process.env.REACT_APP_API_URL}/recentlyWatched`, formData).then((response) => {
      setLoading(false)
      // console.log(response.data);
      // console.log(response.data.VIDEO_STREAMING_APP.recently_watched);
      // console.log(response.data.VIDEO_STREAMING_APP);
      setRecentlyWatched(response.data.VIDEO_STREAMING_APP.recently_watched)
     
    })
  }
  return (
    <>
      {loading ?
        (
          <Loader loading={loading} />) : (
          <>
            <Header />
            {
              searchResults.length > 0 ? (<Search />) : (
                <>
                  <Slider sliders={sliders} />
                  <TopTrendingMovies toptrendings={topTrendings} />
                  {
                    localStorage.getItem('user_id') > 0 ? (
                      <RecentWatch recentlywatched={recentlywatched} />
                    ) :
                      (
                        ''
                      )
                  }
                  <HomeSliderSection homeslidersection={homeslidersection} />
                  <HomeSection homesection={homesection}/>
                  {/* <MostWatched mostwatched={mostwatched} /> */}
                  {/* <Movies movies={movies} /> */}
                  {/* <Genres genres={genres} /> */}
                  {/* <Languages movies={languages} /> */}
                  {/* <LatestMovies movies={latestmovies} /> */}
                  {/* <LatestShows movies={latestshows} /> */}
                  {/* <PopularMovies movies={popularmovies} /> */}
                  {/*   {
              planStatus === 'true' ? (
              <AppDownload/>
              ) : ( '')
            } */}
                  <Footer />
                </>
              )
            }
          </>
        )}
    </>
  )
}
export default Home