import React from 'react'
import { Badge, Stack } from 'react-bootstrap'
import Header from '../Header'
import ForYou from './ForYou'
const TVShowHome = () => {
    const list = [
        'For You',
        'Action',
        'Hollywood In Hindi',
        'Top 10 Movies',
        'Tamil',
        'Romance',
        'Drama',
        'Kannada',
        'Thriller',
        'Comedy',
        'Hindi',
        'Crime',
        'Bangla',
        'Malayalam',
        'English',
        'Telugu',
        'Gujarati'
    ]
    return (
        <>
        <Header />
            <div className='tv-show-list mt-7'>
                <Stack className="text-center" direction="horizontal" gap={2}>
                    {
                        list.map((item) => (
                            <Badge pill bg="light" text='dark' >
                                {item}
                            </Badge>
                        ))
                    }
                </Stack>
            </div>
            <ForYou/>
        </>
    )
}
export default TVShowHome