import React, { useEffect } from 'react'
import Slider from 'react-slick/lib/slider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faCrown } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Row } from 'react-bootstrap';
function HomeSliderSection({ homeslidersection }) {
    // console.log(homeslidersection);
    const CustomNextArrow = ({ onClick }) => (
        <button className="custom-next-arrow" onClick={onClick}>
            <FontAwesomeIcon aria-hidden="true" icon={faAngleRight} />
        </button>
    );
    const CustomPrevArrow = ({ onClick }) => (
        <button className="custom-prev-arrow" onClick={onClick}>
            <FontAwesomeIcon aria-hidden="true" icon={faAngleLeft} />
        </button>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 600,
        slidesToShow: 8,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };
    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "zoom-y-out",
        });
    }, [])
    return (<>
        <div
        >
            {
                homeslidersection.filter(hs => hs.home_title !== 'Top Trending') // Filter out 'Top Trendings' items
                    .map((hs, index) => (
                        <div  className="slider-container" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="200">
                            <h3 className='home-heading'>{hs.home_title}</h3>
                            <Slider {...settings}>
                                {hs.home_content.map((card, cardIndex) => (
                                    <Link to={`/watch/${card.video_id}/${card.video_title}`} key={cardIndex}>
                                        <div className="card-wrapper">
                                            <div className="card">
                                                <div className="image">
                                                    <img
                                                        src={card.video_image}
                                                        alt={card.video_title}
                                                        className="card-img-top slick-slider-img"
                                                    />
                                                </div>
                                                <div className="details">
                                                    <div className="center">
                                                        <h1>{card.video_title}</h1>
                                                        <p>{card.video_type}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </Slider>
                        </div>
                    ))
            }
        </div>
    </>
    )
}
export default HomeSliderSection