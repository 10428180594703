import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Loader from '../Loader/Loader'
import Header from '../Header'
import { Link } from 'react-router-dom'
const MyWatchList = () => {
    const [loading, setLoading] = useState(true)
    const [watchlist, setWatchList] = useState([])
    useEffect(() => {
        loadMyWatchList()
    }, [])
    const loadMyWatchList = async () => {
        try {
            setLoading(true)
            let formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
            axios.post(`${process.env.REACT_APP_API_URL}/my_watchlist`, formData).then((response) => {
                // setLoading(false);
                setLoading(false)
                setWatchList(response.data.VIDEO_STREAMING_APP)
            });
        } catch (error) {
            toast.error(error)
        }
    }
    return (
        <>
            {
                loading ? (<Loader loading={loading} />) :
                    (
                        <>
                            <Header />
                            <Row className='ml-1 mt-6'>
                                <Col md={12} sm={12}><h3 className='home-heading'>My WatchList</h3></Col>
                                {watchlist.map((item, index) => (
                                    <Col md={2} sm={3} xs={4}>
                                        <Link to={`/watch/${item.post_id}`}>
                                            <div key={index} className="card-wrapper">
                                                <div className="card">
                                                    <div className='image'>
                                                        <img src={item.post_image} alt={item.post_title} className="slick-slider-img card-img-top" />
                                                    </div>
                                                    <div class="details">
                                                        <div class="center">
                                                            <h1>{item.post_title}</h1>
                                                            <p>{item.post_type}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        </>
                    )
            }
        </>
    )
}
export default MyWatchList