// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
// import Footer from './components/Footer';

// import Login from './components/Auth/Login';
import Home from './components/Home/Home';
import MovieList from './components/Movies/MovieList';
import TVShowHome from './components/TV_Shows/TVShowHome';
import MovieDetail from './components/Movies/MovieDetail';
import Watch  from './components/Movies/Watch';
import Login from './components/Auth/Login';
import Plans from './components/Plans/Plans';
import PrivateRoute from './components/PrivateRoute';
import MyWatchList from './components/Movies/MyWatchList';

import LanguageMovies from './components/Movies/LanguageMovies';
import GenresMovies from './components/Movies/GenresMovies';
import OTP from './components/Auth/OTP';
import Profile from './components/Auth/Profile';
import { SearchGlobalProvider } from './components/Search/SearchGlobalContext';
function App() {

  
  return (
    <SearchGlobalProvider>
   <Router>
    {/* <Header/> */}
      <Routes>
        <Route path='/' element={<Home/>}/>
         <Route path='/movies' element={<MovieList/>}/>
        <Route path='/tv-shows' element={<TVShowHome/>}/>
        <Route path='/movies/:id' element={<MovieDetail/>}/>
        <Route path='/watch/:id' element={<Watch/>}/>
        <Route path='/watch/:id/:title' element={<Watch/>}/>

        <Route path='/movies_by_language/:id' element={<LanguageMovies/>}/>
        <Route path='/movies_by_genre/:id' element={<GenresMovies/>}/>

        
        <Route path='/login' element={<Login/>}/> 
        <Route path='/otp' element={<OTP/>}/> 
        <Route path='/profile' element={<Profile/>}/> 
        <Route path='/my-watchlist' element={<MyWatchList/>}/> 
        {/* <Route path="/plans" element={<PrivateRoute element={<Plans />} />} /> */}

        <Route path='/plans' element={<Plans/>}/>
        {/* <PrivateRoute path="/plans" element={<Plans />} /> */}
      </Routes>
    </Router>
    </SearchGlobalProvider>
    
    
 
   
 
  
   
  );
}

export default App;
