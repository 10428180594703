import React, { useEffect, useState } from 'react'
import { Col, Container, FormSelect, Row } from 'react-bootstrap'
import Header from '../Header'
import Loader from '../Loader/Loader';
import axios from 'axios';
import Slider from '../Slider';
import Movies from '../Movies';
import DramaMovies from './DramaMovies';
import HorroeMovies from './HorroeMovies';
import RomanticMovies from './RomanticMovies';
import SocialMovies from './SocialMovies';
import SuspenseMovies from './SuspenseMovies';
const MovieList = () => {
    const [loading, setLoading] = useState(true);
    const [sliders, setSlider] = useState([]);
    const [movies, setMovies] = useState([]);
    const [genres, setGrenres] = useState([]);
    const [languages, setLanguages] = useState([]);
    useEffect(() => {
        loadMovies()
        loadGenres()
        loadLanguages()
    }, [])
    const loadMovies = async () => {
        setLoading(true)
        let formData = new FormData();
        formData.append('data[salt]', process.env.REACT_APP_API_SALT);
        formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
        formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
        // formData.append('data[movie_id]', id);
        axios.post(`${process.env.REACT_APP_API_URL}/movies`, formData).then((response) => {
            setLoading(false);
            setSlider(response.data.VIDEO_STREAMING_APP.slider)
            setMovies(response.data.VIDEO_STREAMING_APP.movies)
        })
    }
    const loadGenres = async () => {
        try {
            setLoading(true)
            let formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
            // formData.append('data[movie_id]', id);
            axios.post(`${process.env.REACT_APP_API_URL}/genres`, formData).then((response) => {
                setLoading(false);
                setGrenres(response.data.VIDEO_STREAMING_APP)
            })
        } catch (error) {
        }
    }
    const loadLanguages= async () => {
        try {
            setLoading(true)
            let formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
            // formData.append('data[movie_id]', id);
            axios.post(`${process.env.REACT_APP_API_URL}/languages`, formData).then((response) => {
                setLoading(false);
                // console.log(response.data);
                setLanguages(response.data.VIDEO_STREAMING_APP)
            })
        } catch (error) {
        }
    }
    const list = [
        'For You',
        'Action',
        'Hollywood In Hindi',
        'Top 10 Movies',
        'Tamil',
        'Romance',
        'Drama',
        'Kannada',
        'Thriller',
        'Comedy',
        'Hindi',
        'Crime',
        'Bangla',
        'Malayalam',
        'English',
        'Telugu',
        'Gujarati'
    ]
    return (
        <>
            {
                loading ? (<Loader loading={loading} />) : (
                    <>
                        <Header />
                        {/* <Slider sliders={sliders} /> */}
                        {/* <div className='ml-1 mt-2'>
                           
                                <Row>
                                    <Col sm={2} xs={4}>
                                        <FormSelect className='bg-transparent text-white'>
                                            <option value="">Select Genres</option>
                                            {
                                                genres.map((item) => (
                                                    <option pill bg="dark" value={item.genre_id} text='light' >
                                                        {item.genre_name}
                                                    </option>
                                                ))
                                            }
                                        </FormSelect>̦
                                    </Col>
                                    <Col sm={2} xs={4}>
                                        <FormSelect className='bg-transparent text-white'>
                                            <option value="">Select Language</option>
                                            {
                                                languages.map((item) => (
                                                    <option pill bg="dark" value={item.language_id} text='light' >
                                                        {item.language_name}
                                                    </option>
                                                ))
                                            }
                                        </FormSelect>
                                    </Col>
                                </Row>
                           
                        </div> */}
                        <Movies movies={movies} />
                        <DramaMovies />
                        <HorroeMovies />
                        <RomanticMovies />
                        <SocialMovies/>
                        <SuspenseMovies/>
                    </>
                )
            }
        </>
    )
}
export default MovieList