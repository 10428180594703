import React, { useState, useEffect, useRef, useContext } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar, Container, NavDropdown, Nav, Modal, Button, Form, Col, Row, Image, FormControl } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { SearchGlobalContext } from './Search/SearchGlobalContext';
import axios from 'axios';
const
  Header = () => {
    // const location = useLocation();
    /*   useEffect(() => {
        setActiveMenu(location.pathname);
    }, [location]); */
    const {
      searchKeyword,
      setSearchKeyword,
      searchResults,
      setSearchResults,
    } = useContext(SearchGlobalContext);
    const [activeMenu, setActiveMenu] = useState(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [innerWidth, setInnerWidth] = useState();
    const [user_plan_status, setUserPlansStatus] = useState(null);
    const [inputValue, setInputValue] = useState(searchKeyword);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      const iw = window.innerWidth
      console.log(iw);
      setInnerWidth(iw)
      const handleScroll = () => {
        const offset = window.scrollY;
        setIsScrolled(offset > 50);
      };
    
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [inputValue, setInputValue]);
    const handleSelect = (menu_item) => {
      setActiveMenu(menu_item)
    }
    const user_plan = localStorage.getItem('user_plan_status');
    const name = localStorage.getItem('name');
    const user_id = localStorage.getItem('user_id');
    const navigate = useNavigate();
    // setUserPlansStatus(user_plan);
    let menu;
    if (user_id == null) {
      menu = <Link className={`nav-link mr-auto p-2`} to="/login">
        <Nav className='nav-link'>Login</Nav>
      </Link>
    } else {
      const userLogOut = () => {
        // console.log('logout function', user_plan);
        localStorage.clear();
        navigate('/')
        /*  localStorage.removeItem('user_image')
         localStorage.removeItem('name')
         localStorage.removeItem('subscription_id')
         localStorage.removeItem('user_id')
         localStorage.removeItem('user_plan_status')
         localStorage.removeItem('plan_active')
         localStorage.removeItem('is_parent_user')
         localStorage.removeItem('phone')
    */
        // window.location.reload();
      }
      menu = <NavDropdown className={`mr-auto text-white ml-1  ${innerWidth < 600 ? 'none' : ''} user-profile-menu`} title={<Image src={localStorage.getItem('user_image')} className="user-profile-image" roundedCircle />} id="basic-nav-dropdown">
        <Link to="/profile" className='dropdown-item'>Profile</Link>
        <Link to="/plans" className='dropdown-item'>Plan</Link>
        <Link to="/plans" className='dropdown-item'>Transactions</Link>
        <Link to="/my-watchlist" className='dropdown-item'>My Watchlist</Link>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={userLogOut}>
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    }
    const handleSearchBlur = (event) => {
      setSearchKeyword(event.target.value)
    }
    const handleSearchChange = async (event) => {
      // setSearchKeyword(event.target.value)
      setInputValue(event.target.value)

      if (inputValue) {
        setLoading(true)
        setTimeout(() => {
          let formData = new FormData();
          formData.append('data[salt]', process.env.REACT_APP_API_SALT);
          formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
          formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
          formData.append('data[search_text]', event.target.value);
          axios.post(`${process.env.REACT_APP_API_URL}/new_search`, formData).then((response) => {
            setLoading(false);
            console.log(response.data.VIDEO_STREAMING_APP);
            if (response.data.status_code === 200) {
              console.log(event.target.value);
              
              setInputValue(event.target.value)
              setSearchResults(response.data.VIDEO_STREAMING_APP)
            }
            else {
              setSearchResults([])
            }
          })
        }, 500);
      }
    }
    return (
      <>
        <Navbar expand="lg" className={isScrolled ? 'navbar-scrolled' : 'navbar-transparent'} bg="dark" data-bs-theme="dark"
          fixed='top'>
          <Container>
            <Link to="/" className='p-2' >
              <Nav><img onClick={() => { setSearchResults([]); setInputValue('') }} src={`${process.env.PUBLIC_URL}/logo/masti_plus_logo.svg`} className='logo' alt="logo" title="Masti-Plus" /></Nav>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
              </Nav>
              <FormControl
                style={{ width: "50%" }}
                type='text'
                value={inputValue}
                // onClick={handleSearch}
                // onBlur={handleSearch}
                onChange={handleSearchChange}
                // onBlur={handleSearchBlur}
                className='search-bar'
                placeholder='Search'
              />
              {/*  <Link
              to="/"
              onClick={() => handleSelect('')}
              className={`nav-link mr-auto p-2 ${activeMenu === '' ? 'active-link' : ''}`}  >
              Home
            </Link> */}
              <Link
                to="/movies"
                onClick={() => handleSelect('movies')}
                className={`mr-auto p-2 nav-link ${activeMenu === 'movies' ? 'active-link' : ''}`}>
                Movies
              </Link>
            {/*   <Link
                to="/tv-shows"
                onClick={() => handleSelect('tv-shows')}
                className={`nav-link mr-auto p-2 ${activeMenu === 'tv-shows' ? 'active-link' : ''}`}>
                TV Shows
              </Link> */}
              <Link
                to="/profile"
                className={`nav-link mr-auto p-2  ${innerWidth > 600 ? 'none' : (user_id !== null ? '' : 'none')}  `} >
                Profile
              </Link>
              <Link
                to="/plans"
                className={`nav-link mr-auto p-2  ${innerWidth > 600 ? 'none' : (user_id !== null ? '' : 'none')}  `} >
                Plan
              </Link>
              <Link to="/my-watchlist"
                className={`nav-link mr-auto p-2  ${innerWidth > 600 ? 'none' : (user_id !== null ? '' : 'none')}  `} >
                My Watchlist
              </Link>
              <Link
                onClick={() => { localStorage.clear(); window.location.reload(); }}
                className={`nav-link mr-auto p-2  ${innerWidth > 600 ? 'none' : (user_id !== null ? '' : 'none')}  `} >
                Logout
              </Link>
              {menu}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <ToastContainer />
      </>
    )
  }
export default Header