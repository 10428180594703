import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreLike from './MoreLike';
import Header from '../Header';
import axios from 'axios';
import Loader from '../Loader/Loader';
import { Col, Row } from 'react-bootstrap';
const MovieDetail = () => {
    const { id } = useParams();
    const [movieimage, setMovieImage] = useState(null)
    const [genres, setGenres] = useState([])
    const [movietitle, setMovieTitle] = useState(null)
    const [moviedescription, setMovieDescription] = useState(null)
    const [relatedmovies, setRelateMovies] = useState([])
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Use useNavigate inside the component body
    useEffect(() => {
        getMovieDetails(id)
    }, [id])
    const getMovieDetails = async (id) => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            formData.append('data[user_id]', localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : 271);
            formData.append('data[movie_id]', id);
            axios.post(`${process.env.REACT_APP_API_URL}/movies_details`, formData).then((response) => {
                setLoading(false);
                // console.log(response.data);
                console.log(response.data.VIDEO_STREAMING_APP);
                const formated_url = response.data.VIDEO_STREAMING_APP.movie_image.replaceAll(' ', '%20')
                setMovieImage(formated_url);
                setGenres(response.data.VIDEO_STREAMING_APP.genre_list);
                setMovieTitle(response.data.VIDEO_STREAMING_APP.movie_title);
                setMovieDescription(response.data.VIDEO_STREAMING_APP.description);
                setRelateMovies(response.data.VIDEO_STREAMING_APP.related_movies);
            })
        } catch (error) {
            setLoading(false);
        }
    }
    const handleMoreLikeClick = (newId) => {
        // getMovieDetails(newId);
        navigate(`/movies/${newId}`);
    };
    const backgroundImageStyle = movieimage ? `url(${movieimage.replace(' ', '%')})` : 'none'
    let link = null;
    if (localStorage.getItem('user_id') != undefined)
        link = `/watch/${id}`;
    else
        link = `/login`
    return (
        <>
            {
                loading ?
                    (<Loader loading={loading} />) :
                    (
                        <>
                            <Header />
                            <div
                                id="content_hero"
                                className="center-content hero-ontop"
                                // style={{ backgroundImage: 'url(https://wallpapers.com/images/hd/movie-poster-background-5mlqnzbzbwpziyed.jpg)' }}
                                style={{
                                    backgroundImage: backgroundImageStyle,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: '70vh',
                                }}
                            >
                                <div className="container">
                                    <div
                                        className="row blurb scrollme animateme"
                                        data-when="exit"
                                        data-from="0"
                                        data-to="1"
                                        data-opacity="0"
                                        data-translatey="100"
                                    >
                                        <div className="col-md-12 movie-detail-title">
                                        <div className="buttons movie-detail-btn">
                                                {/* <span className="certificate">PG</span> */}
                                                <Link to={link} className="animated-text  btn btn-sm btn-primary">▶ Play</Link>
                                                {/*  <Link to={link} className="venobox btn btn-default vbox-item">
                                                    <FontAwesomeIcon icon="fa fa-play" />
                                                    <span>Play</span>
                                                </Link> */}
                                            </div>
                                            <span className="title">
                                                {
                                                    genres.map((genre) => (
                                                        genre.genre_name + ' , '
                                                    ))
                                                }
                                            </span>
                                            <h1 className='movie-detail-tile'>{movietitle}</h1>
                                          
                                            <p className='movie-detail-description'>
                                                {moviedescription}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MoreLike relatedmovies={relatedmovies} onMoreLikeClick={handleMoreLikeClick} />
                        </>
                    )
            }
        </>
    );
}
export default MovieDetail