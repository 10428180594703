import React, { useContext, useEffect } from 'react'
import { SearchGlobalContext } from './SearchGlobalContext'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faPencilAlt, faPlay } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
const Search = () => {
    const {
        searchKeyword,
        searchResults
    } = useContext(SearchGlobalContext)
    return (
        <>
            {
                searchResults.length == 0 ? '<h1>Result not found</h1>' : (
                    <>
                        <h3 className="home-heading mt-7">Top Results</h3>
                        <Row>
                            {
                                searchResults.map((card, index) => (
                                    <Col md={3} sm={3} lg={3}>
                                        <Link to={`/watch/${card.id}`}>
                                            <div key={index} className="card-wrapper">
                                                <div className="card">
                                                    <div className="image">
                                                        <img src={card.poster} alt={card.video_title} className="card-img-top slick-slider-img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                ))
                            }
                        </Row>
                    </>
                )
            }
        </>
    )
}
export default Search