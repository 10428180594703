import React, { useEffect, useRef, useState } from 'react'
import Header from '../Header'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import Loader from '../Loader/Loader'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const Plans = () => {
    const navigate = useNavigate();
    const [superPacks, setSuperPack] = useState([])
    const [yearly, setYearlyPack] = useState([])
    const [activePlan, setActivePlan] = useState(116);
    const [mandateURL, setMandateUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(1);
    const [innerWidth, setInnerWidth] = useState();

    const [customerId, setCustomerId] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [paymentAmnt, setPaymentAmnt] = useState(null);
    useEffect(() => {
        const iw = window.innerWidth
        console.log(iw);
        setInnerWidth(iw)
        fetchData();
        // createPaymentMandate(activePlan)
    }, []);
    const fetchData = async () => {
        setLoading(true)
        try {
            let formData = new FormData();
            formData.append('data[salt]', process.env.REACT_APP_API_SALT);
            formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
            axios.post(`${process.env.REACT_APP_API_URL}/subscription_plan`, formData)
                .then((response) => {
                    setLoading(false)
                    const superpackage = response.data.VIDEO_STREAMING_APP.super
                    const yearly = response.data.VIDEO_STREAMING_APP.yearly
                    setSuperPack(superpackage)
                    setYearlyPack(yearly)
                });
        } catch (err) {
            setLoading(false)
            // setError(err);
        } finally {
            // setLoading(false);
        }
    };


    const loadScript = (src) => {
        return new Promise((resolve) => {
          const script = document.createElement('script');
          script.src = src;
          script.onload = () => resolve(true);
          script.onerror = () => resolve(false);
          document.body.appendChild(script);
        });
      };

      
  /*   const createPaymentMandate = (plan_id) => {
        // setLoading(true)
        // console.log(activePlan);
        const phone = localStorage.getItem('phone') != '' ? localStorage.getItem('phone') : null;
        const user_id = localStorage.getItem('user_id') != '' ? localStorage.getItem('user_id') : null;
        const formData = new FormData();
        formData.append('data[salt]', process.env.REACT_APP_API_SALT);
        formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
        formData.append('data[mobileNumber]', phone);
        formData.append('data[merchantUserId]', user_id);
        formData.append('data[description]', '');
        formData.append('data[subscriptionName]', '');
        // formData.append('data[plan_id]', activePlan);
        formData.append('data[plan_id]', plan_id);
        formData.append('data[upi_id]', '');
        axios.post(`${process.env.REACT_APP_API_URL}/createUserSubscriptionLive`, formData).then((response) => {
            // setLoading(false)
            // console.log(response.data);
            const msg = response.data.message;
            const status = response.data.status;
            if (status === false) {
                toast.error(msg, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    // transition: Bounce,
                });
                // toast.error(msg);
            }
            //console.log(msg);
            // toast.error(msg);
            if (response.data.data !== undefined) {
                // console.log(data);
                const data = response.data.data;
                const redirectType = data.redirectType != undefined ? data.redirectType : null;
                const redirectUrl = data.redirectUrl != undefined ? data.redirectUrl : null;
                // setMandateUrl(redirectUrl)
                if (count !== 1) {
                    window.location.href = redirectUrl;
                }
                navigate(redirectUrl)
                setMandateUrl(redirectUrl)
                // navigate(redirectUrl)
                // payBtn.current[activePlan].classList.remove('none')
                // continueBtn.current[activePlan].classList.add('none')
            }
        })
    } */
    const changePlan = async (plan_id) => {

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
          alert('Razorpay SDK failed to load. Check your internet connection.');
          return;
        }
    
        // Fetch payment order details from your backend
          let formData = new FormData();
          formData.append('data[salt]', process.env.REACT_APP_API_SALT);
          formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
          formData.append('data[mobileNumber]', localStorage.getItem('phone'));
          formData.append('data[merchantUserId]', localStorage.getItem('user_id'));
          formData.append('data[plan_id]',plan_id);
          axios.post(`${process.env.REACT_APP_API_URL}/createRazorpayCustomerOrder`, formData)
              .then((response) => {
                  setLoading(false)
                  console.log(response.data);
                //   const customer_id = response.data.customer_id
                  const order_id = response.data.data.order_id
                  const payment_amount = response.data.data.payment_amount



                  const options = {
                    key: "rzp_live_TMDKqrY4r5fWGY", // Replace with your Razorpay key ID
                    amount: payment_amount , // Amount in smallest currency unit (e.g., paise for INR)
                    // amount: 100, // Amount in smallest currency unit (e.g., paise for INR)
                    currency:"INR",
                    order_id: order_id, // The order ID generated from your backend
                    name: "Web User",
                    description: "Tamashaplus OTT",
                  //   image: "https://example.com/your-logo.png",
                    handler: function (response) {
                      navigate('/');
                      // alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
                    },
                    prefill: {
                      name: "Web User",
                      phone:localStorage.getItem('phpne'),
                      email: "webuser@example.com",
                      contact:localStorage.getItem('phone'),
                    },
                    theme: {
                      color: "#3399cc",
                    },
                  };
          
                  console.log(options);
                  
              
                  const paymentObject = new window.Razorpay(options);
                  paymentObject.open();




                //   setCustomerId(customer_id)
                //   setOrderId(order_id)
                //   setPaymentAmnt(payment_amount)
                //   setSuperPack(superpackage)
                //   setYearlyPack(yearly)
              });


              

     
     
   

/* 

        var options = {
            "key": "rzp_live_TMDKqrY4r5fWGY",
            "order_id": "order_1Aa00000000001",
            "customer_id": "cust_1Aa00000000001",
            "recurring": "1",
            "handler": function (response) {
              alert(response.razorpay_payment_id);
              alert(response.razorpay_order_id);
              alert(response.razorpay_signature);
            },
            "notes": {
              "note_key 1": "Beam me up Scotty",
              "note_key 2": "Tea. Earl Gray. Hot."
            },
            "theme": {
              "color": "#F37254"
            }
          };


          var rzp1 = new Razorpay(options);
          document.getElementById('rzp-button1').onclick = function (e) {
            rzp1.open();
            e.preventDefault();
          } */
         return




        // setActivePlan(plan_id)
        setCount(count + 1)
        console.log(plan_id);
        setActivePlan(plan_id)
        // createPaymentMandate(plan_id);
        /* payBtn.current.forEach((pay) => {
            if (pay) {
                pay.classList.add('none');
            }
        });
        continueBtn.current.forEach((cnBtn) => {
            if (cnBtn) {
                cnBtn.classList.remove('none');
                cnBtn.classList.add('disabled');
            }
        }); */
        /* continueBtn.current.forEach((cnBtn) => {
            if (cnBtn) {
                cnBtn.classList.remove('none');
                cnBtn.classList.add('disabled');
            }
        }); */
    }
    return (
        <>
            {
                loading ? (<Loader loading={loading} />) :
                    (
                        <>
                            <Header />
                            <Container className={`${innerWidth < 600 ? '' : 'none'}`}>
                                <Row >
                                    <Col sm={8} md={8} xs={12} className='text-center'>
                                        <Image className='plan-top-image' src={`${process.env.PUBLIC_URL}/images/plans_top_image.png`} />
                                        <span className='plan-top-heading' >Subscribe now and <br /> start streaming</span>
                                        <Card className="plan-benefits mt-3">
                                            <Card.Body>
                                                <span>Key Features</span>
                                                <ul>
                                                    <li>Movies, Shows, web series, documentaries, short movies etc.</li>
                                                    <li>Ad-free movies & shows </li>
                                                    <li>Connect up to 5 devices </li>
                                                    <li>Get a bright & full HD resolution of up to 1080
                                                    </li>
                                                </ul>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={4} md={4} xs={12} className='plan-lists'>
                                        {superPacks.map((item) =>
                                            <Col onClick={() => changePlan(item.plan_id)} id="rzp-button1" md={12} sm={12} xs={12} className={`mt-3 `}
                                            >
                                                <div className={` planmain-div ${item.plan_id === activePlan ? 'plan-active' : 'not-active-plan'}`}>
                                                    <Row>
                                                        {item.plan_id === 116 ? (
                                                            <Col sm={12} md={12} style={{ marginTop: '-6%' }}>
                                                                <span className='recpmmended-plan'>Recommended</span></Col>
                                                        ) : ''}
                                                        <Col sm={1} md={1} xs={1}>
                                                            <input type="radio" name="plan_id" checked={`${item.plan_id === activePlan ? 'checked' : ''}`} />
                                                        </Col>
                                                        <Col sm={3} md={3} xs={3}>
                                                            <span>{item.plan_name}</span>
                                                        </Col>
                                                        <Col sm={4} md={4} xs={4}>
                                                            <Row>
                                                                <Col sm={1} md={1} xs={1} className='payment-amnt-rupee-symb'
                                                                >
                                                                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                                </Col>
                                                                <Col sm={3} md={3} xs={3} className='payment-amnt-div'>
                                                                    <span className='payment-amnt'>{parseInt(item.plan_with_dicount_amount)}</span>
                                                                </Col>
                                                                <Col sm={1} md={1} xs={1} className='payment-dis-amnt-rupee-symb'>
                                                                    <del><FontAwesomeIcon icon={faIndianRupeeSign} /></del>
                                                                    {/* <span className='discount-amnt-rupee-sign'><FontAwesomeIcon style={{fontSize:'10px'}} icon={faIndianRupeeSign} /> </span> */}
                                                                </Col>
                                                                <Col sm={2} md={2} xs={2}>
                                                                    <del><span className='discount-amnt'> {parseInt(item.plan_price)}</span></del>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className={`percentage-off-div  `} sm={4} md={4} xs={4}>
                                                            <span className={`percentage-off-txt   ${item.plan_id === activePlan ? 'active-plan-off' : 'not-active-plan-off'}`}><b>{parseInt(item.discount_percent)}%</b> <small>Off</small></span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )}
                                        {yearly.map((item) =>
                                            <Col md={12} sm={12} xs={12} onClick={() => changePlan(item.plan_id)} className='mt-3'>
                                                <div className={`planmain-div ${item.plan_id === activePlan ? 'plan-active' : 'not-active-plan'}`}>
                                                    <Row>
                                                        <Col sm={1} md={1} xs={1}>
                                                            <input type="radio" name="plan_id" checked={`${item.plan_id === activePlan ? 'checked' : ''}`} />
                                                        </Col>
                                                        <Col sm={3} md={3} xs={3}>
                                                            <span>{item.plan_name}</span>
                                                        </Col>
                                                        <Col sm={4} md={4} xs={4}>
                                                            <Row>
                                                                <Col sm={1} md={1} xs={1} className='payment-amnt-rupee-symb'>
                                                                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                                </Col>
                                                                <Col sm={3} md={3} xs={3} className='payment-amnt-div'>
                                                                    <span className='payment-amnt'>{parseInt(item.plan_with_dicount_amount)}</span>
                                                                </Col>
                                                                <Col sm={1} md={1} xs={1} className='payment-dis-amnt-rupee-symb'>
                                                                    <del><FontAwesomeIcon icon={faIndianRupeeSign} /></del>
                                                                    {/* <span className='discount-amnt-rupee-sign'><FontAwesomeIcon style={{fontSize:'10px'}} icon={faIndianRupeeSign} /> </span> */}
                                                                </Col>
                                                                <Col sm={2} md={2} xs={2}>
                                                                    <del><span className='discount-amnt'> {parseInt(item.plan_price)}</span></del>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className='percentage-off-div' sm={4} md={4} xs={4}>
                                                            <span className={`percentage-off-txt ${item.plan_id === activePlan ? 'active-plan-off' : 'not-active-plan-off'}`}><b>{parseInt(item.discount_percent)}%</b> <small>Off</small></span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        )}
                                        <Col sm={12} md={12} xs={12} className={`mt-5 ${count > 1 ? 'none' : ''}`}>
                                            <a className='btn proceed-btn' href={mandateURL}>Proceed</a>
                                        </Col>
                                    </Col>
                                    {/*   <Col md={12} sm={12} xs={12} className='mt-2'>
                                        <div className='planmain-div'>
                                            <Row>
                                                <Col sm={12} md={12} style={{marginTop:'-6%'}}>
                                                <span className='recpmmended-plan'>Recommended</span></Col>
                                                <Col sm={1} md={1} xs={1}>
                                                    <input type="radio" name="form-control" id="" />
                                                </Col>
                                                <Col sm={3} md={3} xs={3}>
                                                    <span>Daily</span>
                                                </Col>
                                                <Col sm={4} md={4} xs={4}>
                                                    <Row>
                                                        <Col sm={1} md={1} xs={1} className='payment-amnt-rupee-symb'
                                                        >
                                                            <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                        </Col>
                                                        <Col sm={2} md={2} xs={2} className='payment-amnt-div'>
                                                            <span className='payment-amnt'> 7</span>
                                                        </Col>
                                                        <Col sm={1} md={1} xs={1} className='payment-dis-amnt-rupee-symb'>
                                                            <del><FontAwesomeIcon icon={faIndianRupeeSign} /></del>
                                                        </Col>
                                                        <Col sm={2} md={2} xs={2}>
                                                            <del><span className='discount-amnt'> 10</span></del>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className='percentage-off-div' sm={4} md={4} xs={4}>
                                                    <span className='percentage-off-txt'><b>30%</b> <small>Off</small></span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col> */}
                                </Row>
                            </Container>
                            <Row className={`desktop-plan-page-main ${innerWidth > 600 ? '' : 'none'}`} >
                                <Col sm={{ span: 10, offset: 1 }} md={{ span: 4, offset: 4 }} xs={{ span: 10, offset: 1 }} className='text-center'>
                                    <span className='plan-top-heading' >Subscribe now and <br /> start streaming</span>
                                    <Card className="plan-benefits mt-3">
                                        <Card.Body>
                                            <span>Key Features</span>
                                            <ul>
                                                <li>Movies, Shows, web series, documentaries, short movies etc.</li>
                                                <li>Ad-free movies & shows </li>
                                                <li>Connect up to 5 devices </li>
                                                <li>Get a bright & full HD resolution of up to 1080
                                                </li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={{ span: 10, offset: 1 }} md={{ span: 4, offset: 4 }} xs={{ span: 10, offset: 1 }} className='plan-lists'>
                                    {superPacks.map((item) =>
                                        <Col onClick={() => changePlan(item.plan_id)} md={12} sm={12} xs={12} className={`mt-3 `}
                                        >
                                            <div className={` planmain-div ${item.plan_id === activePlan ? 'plan-active' : 'not-active-plan'}`}>
                                                <Row>
                                                    {item.plan_id === 116 ? (
                                                        <Col sm={12} md={12} style={{ marginTop: '-6%' }}>
                                                            <span className='recpmmended-plan'>Recommended</span></Col>
                                                    ) : ''}
                                                    <Col sm={1} md={1} xs={1}>
                                                        <input type="radio" name="plan_id" checked={`${item.plan_id === activePlan ? 'checked' : ''}`} />
                                                    </Col>
                                                    <Col sm={3} md={3} xs={3}>
                                                        <span>{item.plan_name}</span>
                                                    </Col>
                                                    <Col sm={4} md={4} xs={4}>
                                                        <Row>
                                                            <Col sm={1} md={1} xs={1} className='payment-amnt-rupee-symb'
                                                            >
                                                                <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                            </Col>
                                                            <Col sm={3} md={3} xs={3} className='payment-amnt-div'>
                                                                <span className='payment-amnt'>{parseInt(item.plan_with_dicount_amount)}</span>
                                                            </Col>
                                                            <Col sm={1} md={1} xs={1} className='payment-dis-amnt-rupee-symb'>
                                                                <del><FontAwesomeIcon icon={faIndianRupeeSign} /></del>
                                                                {/* <span className='discount-amnt-rupee-sign'><FontAwesomeIcon style={{fontSize:'10px'}} icon={faIndianRupeeSign} /> </span> */}
                                                            </Col>
                                                            <Col sm={2} md={2} xs={2}>
                                                                <del><span className='discount-amnt'> {parseInt(item.plan_price)}</span></del>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className={`percentage-off-div  `} sm={4} md={4} xs={4}>
                                                        <span className={`percentage-off-txt   ${item.plan_id === activePlan ? 'active-plan-off' : 'not-active-plan-off'}`}><b>{parseInt(item.discount_percent)}%</b> <small>Off</small></span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    )}
                                    {yearly.map((item) =>
                                        <Col md={12} sm={12} xs={12} onClick={() => changePlan(item.plan_id)} className='mt-3'>
                                            <div className={`planmain-div ${item.plan_id === activePlan ? 'plan-active' : 'not-active-plan'}`}>
                                                <Row>
                                                    <Col sm={1} md={1} xs={1}>
                                                        <input type="radio" name="plan_id" checked={`${item.plan_id === activePlan ? 'checked' : ''}`} />
                                                    </Col>
                                                    <Col sm={3} md={3} xs={3}>
                                                        <span>{item.plan_name}</span>
                                                    </Col>
                                                    <Col sm={4} md={4} xs={4}>
                                                        <Row>
                                                            <Col sm={1} md={1} xs={1} className='payment-amnt-rupee-symb'>
                                                                <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                            </Col>
                                                            <Col sm={3} md={3} xs={3} className='payment-amnt-div'>
                                                                <span className='payment-amnt'>{parseInt(item.plan_with_dicount_amount)}</span>
                                                            </Col>
                                                            <Col sm={1} md={1} xs={1} className='payment-dis-amnt-rupee-symb'>
                                                                <del><FontAwesomeIcon icon={faIndianRupeeSign} /></del>
                                                                {/* <span className='discount-amnt-rupee-sign'><FontAwesomeIcon style={{fontSize:'10px'}} icon={faIndianRupeeSign} /> </span> */}
                                                            </Col>
                                                            <Col sm={2} md={2} xs={2}>
                                                                <del><span className='discount-amnt'> {parseInt(item.plan_price)}</span></del>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className='percentage-off-div' sm={4} md={4} xs={4}>
                                                        <span className={`percentage-off-txt ${item.plan_id === activePlan ? 'active-plan-off' : 'not-active-plan-off'}`}><b>{parseInt(item.discount_percent)}%</b> <small>Off</small></span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    )}
                                    <Col sm={12} md={12} xs={12} 
                                    className={`mt-5 none`}
                                    // className={`mt-5 ${count > 1 ? 'none' : ''}`}
                                    
                                    >
                                        <a className='btn proceed-btn'
                                        //  onClick={createPaymentMandate(activePlan)}
                                         >Proceed</a>
                                    </Col>
                                </Col>
                                <div className='planpage-overlay'></div>
                            </Row>
                        </>
                    )
            }
            <ToastContainer />
        </>
    )
}
export default Plans