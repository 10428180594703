import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { Card, Col, Container, Form, FormSelect, Image, Row } from 'react-bootstrap'
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import Loader from '../Loader/Loader'
import { toast, ToastContainer } from 'react-toastify'
import Plans from '../Plans/Plans'
import { Link } from 'react-router-dom'
const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [innerWidth, setInnerWidth] = useState();
  const [ageCerti, setAgeCertificate] = useState([]);
  const [userData, setUserData] = useState();
  const [ac, setUserAgeCerti] = useState(0);
  useEffect(() => {
    fetchData()
    const iw = window.innerWidth
    console.log(iw);
    setInnerWidth(iw)
  }, []);
  const fetchData = async () => {
    setLoading(true)
    try {
      let formData = new FormData();
      formData.append('data[salt]', process.env.REACT_APP_API_SALT);
      formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
      formData.append('data[user_id]', localStorage.getItem('user_id'));
      axios.post(`${process.env.REACT_APP_API_URL}/get_all_profile`, formData)
        .then((response) => {
          setLoading(false)
          console.log(response.data.VIDEO_STREAMING_APP[0].user_profiles[0]);
          setUserData(response.data.VIDEO_STREAMING_APP[0].user_profiles[0])
          setUserAgeCerti(response.data.VIDEO_STREAMING_APP[0].age_certificate_id - 1)
          axios.get(`${process.env.REACT_APP_API_URL}/ageCertificate`)
            .then((response) => {
              console.log(response.data.VIDEO_STREAMING_APP[0].data);
              setAgeCertificate(response.data.VIDEO_STREAMING_APP[0].data)
            });
          // convertDate()
        });
    } catch (err) {
      setLoading(false)
      // setError(err);
    } finally {
      // setLoading(false);
    }
  };
  const handleAgeCertificate = () => {
    setLoading(true)
    let formData = new FormData();
    formData.append('data[salt]', process.env.REACT_APP_API_SALT);
    formData.append('data[sign]', process.env.REACT_APP_API_SIGN);
    formData.append('data[user_id]', localStorage.getItem('user_id'));
    formData.append('data[age_certi]', parseInt(ac) + 1);
    axios.post(`${process.env.REACT_APP_API_URL}/update-age-certificate`, formData)
      .then((response) => {
        setLoading(false)
        console.log(response.data.VIDEO_STREAMING_APP[0]);
        toast(response.data.VIDEO_STREAMING_APP[0].msg);
      });
    // ${process.env.REACT_APP_API_URL}/update-age-certificate
  }
  const convertDate = (epD) => {
    const ed = new Date(epD * 1000);
    // console.log(ed);
    const month = ed.getMonth();
    const year = ed.getFullYear();
    const date = ed.getDate();
    return `${date}-${month}-${year}`
  }
  const categories = [
    { value: 1, label: 'U' },
    { value: 2, label: 'U/A 11+' },
    { value: 3, label: 'U/A 13+' },
    { value: 4, label: 'U/A 15+' },
    { value: 5, label: 'A' },
  ];
  const handleChange = (event) => {
    setUserAgeCerti(event.target.value);
    console.log(ageCerti);
  };
  return (
    <>
      {
        loading ? (<Loader loading={loading} />) :
          (
            <>
              <Header />
              <Container >
                <Row className="profile-page-container" >
                  <Col md={5} sm={12} xs={12} >
                    <Card className='profile-card'>
                      <Col md={12} className='text-center'>
                        <img
                          src={userData.user_image}
                          className="card-img-top"
                          alt="Profile"
                          style={{ width: "30%" }}
                        />
                      </Col>
                      <Card.Body >
                        <h5 className="card-title">{userData.name}</h5>
                        <Row>
                          <Col md={6} sm={6} xs={6}>
                            <p className="card-text"><b>Phone:</b> {userData.phone}</p>
                          </Col>
                          <Col md={6} sm={6} xs={6}>
                            <p className="card-text"><b>Current Plan:</b> {userData.plan_amount === '7' ? 'Daily' : (userData.plan_amount === '42' ? 'Weekly' : (userData.plan_amount === '149' ? 'Monthly' : 'Yearly'))}</p>
                          </Col>
                          <Col md={6} sm={6} xs={6}>
                            <p className="card-text"><b>Validity:</b> {convertDate(userData.exp_date)}</p>
                          </Col>
                          <Col md={12} sm={12} xs={12} className='mt-5'>
                            <Link to="/plans" className='upgrade-plan mt-3'>Upgrade Plan</Link>
                          </Col>
                          <Col md={12} sm={12} className='mt-10 text-left'>
                           
                            <Row>
                              {/* {
                                categories.map((a) => { */}
                                  <div style={{width:"20%"}} className='text-left'>
                                    <small>U</small>
                                  </div>
                                  <div style={{width:"20%"}} className='text-center'>
                                    <small>U/A 11+</small>
                                  </div>
                                  <div style={{width:"20%"}} className='text-right'>
                                    <small>U/A 13+</small>
                                  </div>
                                  <div style={{width:"20%"}} className='text-right'>
                                    <small>U/A 15+</small>
                                  </div>
                                  <div style={{width:"20%"}} className='text-right'>
                                    <small>A</small>
                                  </div>
                             {/*    })
                              } */}
                            </Row>
                            <div style={{ width: '100%', margin: '20px auto', position: 'relative' }}>
                              <input
                                type="range"
                                min="0"
                                max={categories.length - 1}
                                step="1"
                                value={ac}
                                onChange={handleChange}
                                style={{ width: '100%' }}
                              />
                             {/*  <div
                                style={{
                                  position: 'absolute',
                                  top: '25px',
                                  left: `${(ac / (categories.length - 1)) * 100}%`,
                                  transform: 'translateX(-50%)',
                                  background: '#fff',
                                  color: "black",
                                  padding: '5px 10px',
                                  border: '1px solid #ccc',
                                  borderRadius: '4px',
                                  whiteSpace: 'nowrap',
                                  pointerEvents: 'none',
                                }}
                              >
                                {categories[ac].label}
                              </div> */}
                            </div>
                            <button type="button" onClick={handleAgeCertificate} className='btn btn-sm age-certi-btn'>Save</button>
                          </Col>
                        </Row>
                        {/* <p className="card-text"><b>Subscription ID:</b> {userData.subscription_id}</p> */}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </>
          )
      }
      <ToastContainer />
    </>
  )
}
export default Profile